import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Paper from "../../../../../components/paper"
import TextLink from "../../../../../components/textlink"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const answer = getAnswer({
    courseId: "haelfte-des-lebens",
    chapterId: "02-entstehungsgeschichte",
    taskId: "gedichtrezension",
  })

  const nl2br = (text) => {
    if (!text) {
      return []
    }
    const regex = /(\r\n|\r|\n)/g
    return text.split(regex).map((line, i) => {
      if (line.match(regex)) {
        return <br key={`br-${i}`} />
      }
      return line
    })
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/zusammenfassung" />
      }
    >
      <Seo title="Eine Gedichtrezension verfassen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Eine Gedichtrezension verfassen
            </Heading>
          </Stack>
          <Paper>
            <Paragraph as="div">
              <p>{nl2br(answer?.intro)}</p>
              <br />
              <p>{nl2br(answer?.main)}</p>
              <br />
              <p>{nl2br(answer?.end)}</p>
            </Paragraph>
          </Paper>
          <Paragraph>
            Hast du Lust, deine Rezension mit uns zu teilen? Dann schicke sie
            uns per Mail an{" "}
            <TextLink href="mailto:hoelderlinturm@tuebingen.de">
              hoelderlinturm@tuebingen.de
            </TextLink>
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
